import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'available-credit'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

type IframeUrl = {
  url: string
  isForApplicant: boolean
}

type Signature = {
  signerFirstName: string
  signerLastName: string
  signerEmail: string
  signatureReceptionDate?: Date
}

export type IframeDto = {
  urls: IframeUrl[]
  completedSignatures: Signature[]
}

const getContractIframeUrl = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<IframeDto>(
    `/api/creditApplication/${encodeURIComponent(id)}/contract/iframeLink`,
  )

  return response.data
}

export function useGetContractIframeUrl(
  creditApplicationId: string,
  enabled?: boolean,
): [IframeDto | undefined, boolean, number] {
  const { isFetching, failureCount, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getContractIframeUrl,
    enabled,
    retry: 4, // Retry up to 4 times on failure
    retryDelay: 15000, // Refetch every 15 seconds
  })

  return [data, isFetching, failureCount]
}
