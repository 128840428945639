import { ComputeAmountForFrequencyDto, TermAmountPair, useComputePaymentAmountForFrequency } from '@src/api/compute-api'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { getLoanAmount } from '@src/data/credit-application-selectors'
import { Constants, EMPTY_ARRAY, EPaymentFrequency } from '@src/types'
import { useMemo } from 'react'

const useTermPaymentsTable = (creditApp: FilteredCreditApplication | null): [TermAmountPair[], boolean] => {
  const dto: ComputeAmountForFrequencyDto | null = useMemo(() => {
    if (creditApp && creditApp.merchantPaymentPlanId === null) {
      return {
        amount: Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced),
        adminFee: Constants.FinanceFeeRateForRegular * getLoanAmount(creditApp),
        rate: creditApp.finalDecision.interestRate,
        maxPmtAmount: creditApp.finalDecision.maxPmtAmount,
        paymentFrequency: EPaymentFrequency.Monthly,
        activationDate: new Date(),
      }
    }
    return null
  }, [creditApp])
  const [result, isLoading] = useComputePaymentAmountForFrequency(dto)
  return [result?.termAmountPair ?? EMPTY_ARRAY, isLoading]
}

export default useTermPaymentsTable
