import { useGetCreditApplicationById } from '@src/api/credit-api'
import Loader from '@src/components/Loader'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useTermPaymentsTable from '../ViewCreditApplication/InitialApprobationPage/hooks'
import SelectTermForm from './SelectTermForm'

const SelectTermPage = () => {
  const { id } = useParams()
  const [shouldPoll, setShouldPoll] = useState(false)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id as string, shouldPoll)
  const [monthlyPaymentPerMonths, isLoadingTermTable] = useTermPaymentsTable(creditApp)
  const { t } = useTranslation()

  useEffect(() => {
    if (!creditApp && !isFetchingCreditApp) {
      setShouldPoll(true)
    }
  }, [creditApp, isFetchingCreditApp])

  if (!creditApp || isFetchingCreditApp || isLoadingTermTable) {
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  }

  return <SelectTermForm creditApp={creditApp} monthlyPaymentPerMonths={monthlyPaymentPerMonths} />
}

export default SelectTermPage
