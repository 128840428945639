import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { extractNumbersFromMerchantId } from '@src/data/merchant-selectors'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { ECreditApplicationStatus } from '@src/types'

type Props = {
  creditApp: FilteredCreditApplication
}

const ClosedApplicationTile = ({ creditApp }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const merchantId = extractNumbersFromMerchantId(creditApp.merchantId)

  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.financeApplication')}</h4>
      {creditApp.status === ECreditApplicationStatus.Completed && (
        <h1 style={{ marginBottom: '2rem' }}>{t(`common.completedTitle`)}</h1>
      )}
      <h3>
        <p className="black-strong">{t(`common.${creditApp.status}`)}</p>
      </h3>
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          className="btn btn-blue primary-action-btn"
          onClick={() => {
            navigate(`/${merchantId}`, { replace: true })
          }}
        >
          {t('common.newApplication')}
          <i className="fa-regular fa-paper-plane-top" />
        </button>
      </div>
    </main>
  )
}

export default ClosedApplicationTile
