import { useBadReviewReasons } from '@src/api/config-api'
import { CustomerFeedbackDto, useSubmitFeedback } from '@src/api/feedback-api'
import { AsyncActionButton, Loader } from '@src/components'
import BackButton from '@src/components/BackButton'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import FeedbackOption from '../CancelApplication/FeedbackOption'

const BadCustomerReviewPage = () => {
  const { t, i18n } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const navigate = useNavigate()

  const [badReviewReasons, isLoadingReasons] = useBadReviewReasons(i18n.language)
  const [selectedReason, setSelectedReason] = useState('')
  const [submitFeedback, isSubmittingFeedback] = useSubmitFeedback()
  const [feedbackGiven, setFeedbackGiven] = useState(false)

  const buildFeedbackDto = useCallback(
    (contactClient: boolean): CustomerFeedbackDto => ({
      withdrawalReasonId: '',
      contactClient,
      isSatisfied: false,
      badReviewReasonId: selectedReason,
      creditApplicationId: id,
    }),
    [id, selectedReason],
  )

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    const dto = buildFeedbackDto(false)
    submitFeedback(dto)
      .then(() => setFeedbackGiven(true))
      .catch(reportErrorToConsole)
  }

  if (isLoadingReasons) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return !feedbackGiven ? (
    <main className="form-section">
      <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
        <div className="control-group radiobuttons-stacked-container">
          <h1 style={{ margin: '2rem auto' }}>{t('feedback.formTitle')}</h1>
          <div>
            <p className="paragraph">{t('feedback.formSubtitle')}</p>
          </div>
          {badReviewReasons?.map((r) => (
            <div className="radiobuttons-stacked-cards">
              <FeedbackOption
                key={r.id}
                id={r.id}
                text={r.text}
                selected={selectedReason}
                onChange={setSelectedReason}
              />
            </div>
          ))}
        </div>
        <div className="btn-group mobile-reversed">
          <BackButton />
          <AsyncActionButton type="submit" className="btn btn-blue" isPending={isSubmittingFeedback}>
            {t('common.submit')}
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>
        </div>
      </form>
    </main>
  ) : (
    <main className="general-message">
      <h3>{t('feedback.thankYou')}</h3>
      <br />
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          onClick={() => navigate(`/creditapplication/${id}`)}
          className="btn btn-blue primary-action-btn"
        >
          <i className="fa-light fa-arrow-left" />
          {t('common.backToApplication')}
        </button>
      </div>
    </main>
  )
}

export default BadCustomerReviewPage
