import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { ManageCoappButton } from '@src/components'
import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DeclinedCancelAppButton from './DeclinedCancelAppButton'

type Props = {
  creditApp: FilteredCreditApplication
}

const DeclinedDecisionForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    trackEvent(ETrackedEvents.QualifDeclined, creditApp)
  }, [creditApp])

  return (
    <main className="form-section" style={{ textAlign: 'center' }}>
      <h4 className="subtitle">{t('common.financeApplication')}</h4>
      <h3 style={{ textAlign: 'start' }}>
        {t(`finalDecision.refusedHasCoApplicant.${creditApp.coapplicant !== null}`, {
          forStep: t('common.qualified'),
        })}
      </h3>
      <div className="btn-group mobile-reversed">
        <DeclinedCancelAppButton creditApp={creditApp} cancelEventName={ETrackedEvents.WithdrewAfterQualif} />
        <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={creditApp.coapplicant !== null} />
      </div>
    </main>
  )
}

export default DeclinedDecisionForm
