import { ReactNode } from 'react'

import { Link } from 'react-router-dom'

export enum CreditStepStatus {
  NotReady = 'not-ready',
  Await = 'await',
  RequiresAttention = 'required',
  Completed = 'completed',
}

type ConnectorProps = {
  ready: boolean
}
const Connector = ({ ready }: ConnectorProps) => {
  const cls = ready ? 'connector ready' : 'connector not-ready'
  return (
    <div className="connectorContainer">
      <div className={cls} />
    </div>
  )
}

type Props = {
  status: CreditStepStatus
  linkToDetail: string
  hideLinkToDetail?: boolean
  noConnector?: boolean
  title: string
  content: string
  isPending?: boolean
}

type LinkWrapperProps = {
  children: ReactNode
  to: string
  hideLinkToDetail: boolean
}
const LinkWrapper = ({ children, to, hideLinkToDetail }: LinkWrapperProps) => {
  if (hideLinkToDetail) return <div className="credit-step">{children}</div>
  return (
    <Link to={to} className="credit-step">
      {children}
    </Link>
  )
}

const CreditStep = ({
  status,
  linkToDetail,
  hideLinkToDetail = false,
  noConnector = false,
  title,
  content,
  isPending,
}: Props) => {
  let icon = ''

  if (isPending) icon = 'fa fa-spinner fa-spin icon'
  else if (status === CreditStepStatus.Await) icon = 'fas fa-hourglass-half'
  else if (status === CreditStepStatus.RequiresAttention) icon = 'fas fa-exclamation'
  else if (status === CreditStepStatus.Completed) icon = 'fas fa-check'

  return (
    <>
      {!noConnector && <Connector ready={status !== CreditStepStatus.NotReady} />}
      <LinkWrapper to={linkToDetail} hideLinkToDetail={hideLinkToDetail}>
        <>
          <div className={`stepStatus ${status}`}>
            <i className={icon} />
          </div>
          <div className={`credit-step-content ${status}`}>
            <div className="step-name">{title}</div>
            <div className="step-description">{content}</div>
          </div>
          {!hideLinkToDetail && <i className="fa-regular fa-arrow-right blue-link" style={{ fontSize: '18px' }} />}
        </>
      </LinkWrapper>
    </>
  )
}

export default CreditStep
