import { yupResolver } from '@hookform/resolvers/yup'
import { useGetPromotionalCreditAppPaymentAmount } from '@src/api/compute-api'
import { FilteredCreditApplication, useSubmitHardHit } from '@src/api/credit-api'
import {
  AcceptTermsCheckbox,
  AsyncActionButton,
  CancelAppButton,
  ExpandableSection,
  InputText,
  ManageCoappButton,
  SinInput,
} from '@src/components'
import RemoveCoappButton from '@src/components/RemoveCoappButton'
import {
  EditSinAndLoanAmount,
  buildEditSinAndLoanAmountSchema,
} from '@src/containers/ViewCreditApplication/components/EditPrequalificationSchema'
import {
  getMaxPaymentForRequestedAmount,
  getMinPaymentForRequestedAmount,
  getPrequalifiedAmount,
  hasFlexPlan,
  mustAskApplicantSIN,
  mustAskCoapplicantSIN,
} from '@src/data/credit-application-selectors'
import { reportErrorToConsole } from '@src/services/error-logger'
import {
  FormatCurrencyNoDecimals,
  FormatCurrencyRoundedUpToNoDecimals,
  FormatCurrencyToNearestLowerMultipleToNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { Constants } from '@src/types'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useComputeMinMaxPaymentForLoanAmount } from '../../credit-hooks'
import MonthlyPaymentRange from './MonthlyPaymentRange'

type Props = {
  creditApp: FilteredCreditApplication
}

const ApprovedPrequalForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const initialPrequalifiedAmount = getPrequalifiedAmount(creditApp)
  const minPaymentForInitialApprovedAmount = getMinPaymentForRequestedAmount(creditApp)
  const maxPaymentForInitialApprovedAmount = getMaxPaymentForRequestedAmount(creditApp)
  const askApplicantSIN = mustAskApplicantSIN(creditApp)
  const askCoapplicantSIN = mustAskCoapplicantSIN(creditApp)
  const hasPromotion = !!creditApp.merchantPaymentPlanId
  const { requestedLoanAmount } = creditApp
  const maxLoanAmout = creditApp.prequalificationDecision.maxLoanAmount

  const shouldProposeCoapplicant = maxLoanAmout < requestedLoanAmount
  const shouldProposeHigherLoan = maxLoanAmout >= requestedLoanAmount
  const hasCoapplicant = creditApp.coapplicant != null

  const hasFlex = hasFlexPlan(creditApp)

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalSuccess)

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm<EditSinAndLoanAmount>({
    mode: 'onBlur',
    defaultValues: {
      needsApplicantSin: askApplicantSIN,
      needsCoapplicantSin: askCoapplicantSIN === true,
      requestedLoanAmount: initialPrequalifiedAmount,
      id: creditApp.id,
    },
    resolver: yupResolver(buildEditSinAndLoanAmountSchema(creditApp.prequalificationDecision.maxLoanAmount)),
  })

  const newLoanAmount = watch('requestedLoanAmount')
  const updatedMonthlyPayment = useComputeMinMaxPaymentForLoanAmount(creditApp, newLoanAmount)

  const isValidLoanAmount = useCallback(
    (loanAmount: number) => {
      return (
        creditApp.prequalificationDecision != null &&
        loanAmount <= creditApp.prequalificationDecision.maxLoanAmount &&
        loanAmount >= Constants.MinLoanAmount
      )
    },
    [creditApp],
  )

  const [submitHardHit, isSubmitting] = useSubmitHardHit()
  const innerSubmit = useCallback(
    (data: EditSinAndLoanAmount) => {
      submitHardHit(data).catch(reportErrorToConsole)
    },
    [submitHardHit],
  )

  const [promotionalPaymentAmounts] = useGetPromotionalCreditAppPaymentAmount(
    creditApp.id,
    hasPromotion,
    creditApp.requestedLoanAmount,
  )

  const mustShowUpdatedMonthlyPayment =
    !hasPromotion &&
    isValidLoanAmount(newLoanAmount) &&
    (Math.abs(maxPaymentForInitialApprovedAmount - updatedMonthlyPayment[1]) > 5 ||
      Math.abs(minPaymentForInitialApprovedAmount - updatedMonthlyPayment[0]) > 5)

  const prequalifiedMessage =
    initialPrequalifiedAmount >= creditApp.requestedLoanAmount
      ? 'prequalification.prequalified'
      : 'prequalification.prequalifiedNotRequestedAmount'

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={handleSubmit(innerSubmit)}>
        <h1 className="h3">
          {!hasPromotion && (
            <Trans
              i18nKey={prequalifiedMessage}
              values={{
                requestedAmount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
                minMonthlyPayment: FormatCurrencyToNearestLowerMultipleToNoDecimals(
                  minPaymentForInitialApprovedAmount,
                  5,
                ),
                maxMonthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                  maxPaymentForInitialApprovedAmount,
                  5,
                ),
              }}
            />
          )}
          {hasPromotion && promotionalPaymentAmounts?.paymentForFrequency && (
            <Trans
              i18nKey="prequalification.prequalifiedVariableInterest"
              values={{
                requestedAmount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
                monthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                  promotionalPaymentAmounts.paymentForFrequency,
                  5,
                ),
              }}
            />
          )}
        </h1>
        <div className="paragraph">
          <p className="information">
            <Trans i18nKey="prequalification.loanDetails.mainDetails" />
          </p>
          <ExpandableSection toggleText="prequalification.loanDetails.learnMore">
            <p
              style={{
                fontStyle: 'italic',
                border: '1px solid #08abf0',
                borderRadius: '1.5rem',
                textAlign: 'center',
                padding: '1.5rem',
              }}
            >
              {t('prequalification.loanDetails.learnMoreDetails')}
            </p>
          </ExpandableSection>

          {shouldProposeHigherLoan && (
            <p className="blue-strong">
              <Trans
                i18nKey="prequalification.prequalificationMaxAmount"
                values={{
                  maxLoanAmount: FormatCurrencyNoDecimals(creditApp.prequalificationDecision?.maxLoanAmount),
                }}
              />
            </p>
          )}

          {shouldProposeCoapplicant && (
            <p>
              <Trans
                i18nKey="prequalification.addCoapplicant.requestedAmount"
                values={{
                  requestedAmount: FormatCurrencyRoundedUpToNoDecimals(requestedLoanAmount),
                }}
              />
              <br />
              <Link className="blue-link" to={`/creditapplication/${creditApp.id}/addcoapplicant/personal-information`}>
                <Trans
                  i18nKey={
                    hasCoapplicant
                      ? 'prequalification.addCoapplicant.changeLink'
                      : 'prequalification.addCoapplicant.addLink'
                  }
                />
              </Link>{' '}
              <Trans i18nKey="prequalification.addCoapplicant.subject" />
            </p>
          )}

          <p className="blue-strong">
            <Trans i18nKey="prequalification.modifyAmountRequested" />
          </p>

          <InputText
            id="requestedLoanAmount"
            type="number"
            max={creditApp.prequalificationDecision.maxLoanAmount}
            min={Constants.MinLoanAmount}
            wrapStyle="number-wrap"
            label="prequalification.confirmLoanAmount"
            {...register('requestedLoanAmount')}
            error={errors?.requestedLoanAmount}
            errorParams={{ maxLoanAmount: creditApp.prequalificationDecision?.maxLoanAmount.toString() }}
          />

          {mustShowUpdatedMonthlyPayment && (
            <MonthlyPaymentRange min={updatedMonthlyPayment[0]} max={updatedMonthlyPayment[1]} />
          )}

          {askApplicantSIN && (
            <SinInput
              error={errors.applicantSin}
              id="applicantSin"
              reason="prequalification.applicantCreditLessThan2Years"
              {...register('applicantSin')}
            />
          )}

          {askCoapplicantSIN && (
            <SinInput
              error={errors.coapplicantSin}
              id="coapplicantSin"
              reason="prequalification.coapplicantCreditLessThan2Years"
              {...register('coapplicantSin')}
            />
          )}

          <p style={{ fontWeight: 'bold' }}>{t('prequalification.pullCreditReport')}</p>
          <AcceptTermsCheckbox
            id="acceptConditions"
            termId="prequalification.acceptConditions"
            msgOnInvalid="common.acceptConditions"
          />
        </div>
        {creditApp.coapplicant !== null ? (
          <div id="prequal-details-coapp-btn-group">
            <RemoveCoappButton creditAppId={creditApp.id} />
            <ManageCoappButton creditAppId={creditApp.id} useChangeLabel />
            <CancelAppButton creditAppId={creditApp.id} />
            <AsyncActionButton type="submit" isPending={isSubmitting} primary style={{ justifyContent: 'center' }}>
              {t('prequalification.continue')}
              <i className="fa-regular fa-arrow-right" />
            </AsyncActionButton>
          </div>
        ) : (
          <div className="btn-group mobile-reversed">
            <CancelAppButton creditAppId={creditApp.id} />
            <ManageCoappButton creditAppId={creditApp.id} />
            <AsyncActionButton type="submit" isPending={isSubmitting} primary style={{ justifyContent: 'center' }}>
              {t('prequalification.continue')}
              <i className="fa-regular fa-arrow-right" />
            </AsyncActionButton>
          </div>
        )}

        <div style={{ borderTop: '1px solid black', marginTop: '5rem' }}>
          <p style={{ marginTop: '2rem' }}>
            {!hasPromotion && (
              <Trans
                i18nKey="prequalification.priorToQualifyNotes.1"
                values={{
                  minInterestRate: creditApp.prequalificationDecision.minInterestRate,
                  ageConditionalTerm:
                    creditApp.applicant.age >= Constants.UpperThresholdAgeForApplicant
                      ? Constants.CorrectedMaxTerm
                      : Constants.BaseMaxTerm,
                }}
              />
            )}
            {hasPromotion && promotionalPaymentAmounts?.paymentForFrequency && (
              <span>
                <Trans
                  i18nKey="prequalification.priorToQualifyNotes.promoSub"
                  values={{
                    reducedInterestRate: creditApp.merchantPaymentPlan.reducedInterestRate,
                  }}
                />
                <Trans
                  i18nKey={`prequalification.priorToQualifyNotes.${hasFlex ? 'flexInterest' : 'flashInterest'}`}
                  values={{
                    reducedInterestRateDurationInMonth: creditApp.merchantPaymentPlan.reducedRateDurationInMonths,
                    interestRate: creditApp.merchantPaymentPlan.interestRate,
                  }}
                />
                &nbsp;
                {creditApp.merchantPaymentPlan.borrowerFeeRate > 0 && (
                  <>
                    <Trans
                      i18nKey="prequalification.priorToQualifyNotes.fees"
                      values={{
                        borrowerFee: creditApp.merchantPaymentPlan.borrowerFeeRate,
                      }}
                    />
                    &nbsp;
                  </>
                )}
                {t('prequalification.priorToQualifyNotes.estimate')}
              </span>
            )}
          </p>
          <p style={{ marginTop: '2rem' }}>{t('prequalification.priorToQualifyNotes.2')}</p>
        </div>
      </form>
    </main>
  )
}

export default ApprovedPrequalForm
