import { useTranslation } from 'react-i18next'
import { EContractStatus, FilteredCreditApplication } from '@src/api/credit-api'
import {
  applicantFullName,
  areApplicantIncomesAreUnderReview,
  hasMissingDocuments,
} from '@src/data/credit-application-selectors'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'
import { useGetContractIframeUrl } from '@src/api/contract-api'
import { useEffect, useState } from 'react'
import CreditStep, { CreditStepStatus } from './CreditStep'

type ContractCreditStepProps = {
  applicantType: EApplicantType
  creditApp: FilteredCreditApplication
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}
const ContractCreditStep = ({
  creditApp,
  skipBankAccountRequiredDocuments,
  applicantType,
}: ContractCreditStepProps) => {
  const { t } = useTranslation()

  const docStepDone = !hasMissingDocuments(creditApp)
  const applicant = applicantType === EApplicantType.Applicant ? creditApp.applicant : creditApp.coapplicant!

  const applicantIncomeStepDone =
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.Applicant, skipBankAccountRequiredDocuments) ||
    creditApp.applicant.areIncomesConfirmed

  const coapplicantIncomeStepDone =
    creditApp.coapplicant === null ||
    creditApp.coapplicant.areIncomesConfirmed ||
    areApplicantIncomesAreUnderReview(creditApp, EApplicantType.CoApplicant, skipBankAccountRequiredDocuments)

  const contractIsReceived = [
    EContractStatus.UnderReview,
    EContractStatus.Completed,
    EContractStatus.Declined,
  ].includes(creditApp.contract.status)

  const isContractReady = creditApp.contract.status === EContractStatus.Ready

  const [shouldPoll, setShouldPoll] = useState(isContractReady)

  let content = ''
  let status = isContractReady ? CreditStepStatus.RequiresAttention : CreditStepStatus.NotReady

  const [iframeDto, isLoadingLink, failureCount] = useGetContractIframeUrl(creditApp.id, shouldPoll)

  useEffect(() => {
    if (iframeDto || failureCount >= 4) setShouldPoll(false)
  }, [iframeDto, failureCount])

  const urlsDto = iframeDto?.urls?.find((x) => x.isForApplicant === (applicantType === EApplicantType.Applicant))

  const hideLinkToDetail = contractIsReceived || !creditApp.cvtNumber || isLoadingLink || !urlsDto

  const applicantHasCompleted =
    applicantType === EApplicantType.Applicant && iframeDto && iframeDto.completedSignatures.length > 0

  if (
    creditApp.contract.status === EContractStatus.NotReady &&
    docStepDone &&
    applicantIncomeStepDone &&
    coapplicantIncomeStepDone
  ) {
    status = CreditStepStatus.Await
    content = t('overview.contractWait')
  } else if (isContractReady || urlsDto) {
    content = urlsDto ? t('overview.contractReady') : ''
    if (applicantHasCompleted || (iframeDto && iframeDto.completedSignatures.length > 1)) {
      status = CreditStepStatus.Completed
    }
  } else if (contractIsReceived) {
    status = CreditStepStatus.Completed
    content = `${t('contract.received')} ${t('contract.review')}`
  }

  return (
    <CreditStep
      isPending={shouldPoll}
      status={status}
      linkToDetail={urlsDto?.url as string}
      hideLinkToDetail={hideLinkToDetail}
      title={t('overview.contractStep', { name: applicantFullName(applicant) })}
      content={content}
    />
  )
}

export default ContractCreditStep
