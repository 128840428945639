import { FilteredCreditApplication, useCancelApplication } from '@src/api/credit-api'
import { AsyncActionButton } from '@src/components'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  creditApp: FilteredCreditApplication
  cancelEventName: ETrackedEvents
}

const CancelAppButton = ({ creditApp, cancelEventName }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitCancel, isCancelling] = useCancelApplication()

  const onCancel = () => {
    trackEvent(cancelEventName, creditApp)
    submitCancel(creditApp.id)
      .then(() => {
        navigate(`/creditapplication/${creditApp.id}`)
      })
      .catch(reportErrorToConsole)
  }

  return (
    <AsyncActionButton type="button" className="btn" onClick={onCancel} isPending={isCancelling}>
      <i className="fa-regular fa-ban" />
      {t('common.cancelMyApp')}
    </AsyncActionButton>
  )
}

export default CancelAppButton
