import { FilteredCreditApplication } from '@src/api/credit-api'
import { CustomerFeedback } from '@src/api/feedback-api'
import { EApplicantType, EExternalStep, ERequiredExternalStepStatus, RequiredDocumentWithStatus } from '@src/types'
import {
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/credit-application-selectors'
import { CreditStepStatus } from './OverviewPage/components/CreditStep'

export function hasCompletedFlinks(creditApp: FilteredCreditApplication | null): boolean {
  const found = creditApp?.requiredExternalSteps?.find(
    (x) => x.applicantType === EApplicantType.Applicant && x.externalStepId === EExternalStep.BankAccount,
  )

  if (found) {
    return (
      found.status === ERequiredExternalStepStatus.WaitingForBank ||
      found.status === ERequiredExternalStepStatus.Completed
    )
  }
  return false
}

export function isReadyForCustomerFeedback(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  if (!creditApp) return false
  return (
    creditApp.allIncomesConfirmed ||
    hasApplicantSubmittedFlinks(creditApp, EApplicantType.Applicant) ||
    hasApplicantSubmittedAllRequiredIncomeDocuments(skipBankAccountRequiredDocuments, EApplicantType.Applicant)
  )
}

export function getFeedbackStepStatus(
  creditApp: FilteredCreditApplication | null,
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[],
  feedback: CustomerFeedback | null,
) {
  if (!isReadyForCustomerFeedback(creditApp, skipBankAccountRequiredDocuments)) return CreditStepStatus.NotReady
  if (!feedback) return CreditStepStatus.RequiresAttention
  return CreditStepStatus.Completed
}
