import { useGetPromotionalCreditAppPaymentAmount } from '@src/api/compute-api'
import { FilteredCreditApplication } from '@src/api/credit-api'
import BackButton from '@src/components/BackButton'
import CancelAppButton from '@src/components/CancelAppButton'
import ManageCoappButton from '@src/components/ManageCoappButton'
import RemoveCoappButton from '@src/components/RemoveCoappButton'
import { useTermAmountOptions } from '@src/data/credit-application-hooks'
import { applicantFullName, hasSignedContract } from '@src/data/credit-application-selectors'
import { FormatCurrency, FormatCurrencyToNearestUpperMultipleToNoDecimals } from '@src/services/Formatter'
import { Constants } from '@src/types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useTermPaymentsTable from '../../InitialApprobationPage/hooks'

type Props = {
  creditApp: FilteredCreditApplication
}

const CreditSummaryPage = ({ creditApp }: Props) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const creditApplicationId = creditApp.id
  const hasPromotion = creditApp.merchantPaymentPlanId !== null
  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp)
  const [promotionalAmounts] = useGetPromotionalCreditAppPaymentAmount(
    creditApplicationId,
    hasPromotion,
    creditApp.requestedLoanAmount,
  )
  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths, i18n.language)
  const selectedTermOption = termOptions.find((x) => x.id === creditApp.worksheet.term.toString())
  const hasSignedCvt = hasSignedContract(creditApp)

  if (isLoadingTerms) return null
  return (
    <main className="form-section">
      <div className="credit-steps">
        <div className="applicationInfo-card paragraph">
          <h3>{t('common.appNumber', { number: creditApp.referenceNumber })}</h3>
          <div>
            <p className="applicationInfo-card-subtitle">
              <strong>{t('common.applicant')} : </strong>
              {applicantFullName(creditApp.applicant)}
            </p>
            <p className="applicationInfo-card-subtitle">
              <strong>{t('common.coapplicant')} : </strong>
              {creditApp.coapplicant !== null ? applicantFullName(creditApp.coapplicant) : t('common.none')}
            </p>
          </div>
          {!hasSignedCvt && (
            <div className="btn-group">
              <ManageCoappButton creditAppId={creditApp.id} useChangeLabel={creditApp.coapplicant !== null} />
              {creditApp.coapplicant !== null && <RemoveCoappButton creditAppId={creditApp.id} />}
              <CancelAppButton creditAppId={creditApp.id} />
            </div>
          )}
        </div>
        <div className="applicationInfo-card paragraph">
          <h3>{t('loan.details')}</h3>
          <div>
            <p>
              <strong>{t('common.maxLoanAmount')} : </strong>
              {FormatCurrency(creditApp.finalDecision.maxAmountFinanced)}
            </p>
            <p>
              <strong>{t('common.loanAmount')} : </strong>
              {FormatCurrency(creditApp.worksheet.amountRequested)}
            </p>
            {!hasPromotion && selectedTermOption && (
              <>
                <p>
                  <strong>{t('common.monthlyPayment')} : </strong>
                  {selectedTermOption.monthsEstimatedAt}
                </p>
                {!hasSignedCvt && (
                  <div className="btn-group">
                    <button
                      className="btn secondary-action-btn btn-simple"
                      type="button"
                      onClick={() => navigate(`/creditApplication/${creditApplicationId}/select-term`)}
                    >
                      <i className="fa-light fa-pen" />
                      {t('loan.change')}
                    </button>
                  </div>
                )}
                <p>
                  {t('common.monthlyAmountsInclude', {
                    financeFee: Math.round(Constants.FinanceFeeRateForRegular * 100),
                    interestRate: creditApp?.finalDecision.interestRate,
                  })}
                </p>
              </>
            )}
            {hasPromotion && promotionalAmounts && (
              <>
                <p>
                  <strong>{t('common.monthlyPayment')} : </strong>
                  {t('loan.monthPeriodItem', {
                    loanTerm: creditApp.merchantPaymentPlan.loanTerm,
                    loanAmount: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                      promotionalAmounts.paymentForFrequency,
                      5,
                    ),
                  })}
                </p>
                {creditApp.merchantPaymentPlan.borrowerFeeRate > 0 && (
                  <p>{t('decision.includedFee', { adminFeeRate: creditApp.merchantPaymentPlan.borrowerFeeRate })}</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <BackButton />
    </main>
  )
}

export default CreditSummaryPage
