import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CancelAppButton } from '@src/components'
import { EApplicationStep } from '@src/types'
import UploadFilesTable from '@src/components/UploadFilesTable'
import ManageCoappButton from '@src/components/ManageCoappButton'
import RemoveCoappButton from '@src/components/RemoveCoappButton'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { hasMissingDocuments } from '@src/data/credit-application-selectors'
import UnderReviewTile from '../../components/UnderReviewTile'

type Props = {
  creditApp: FilteredCreditApplication
}
const FailedToObtainCoappCreditReportForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const hasMissingDocs = hasMissingDocuments(creditApp, EApplicationStep.Credit)

  return hasMissingDocs ? (
    <main className="general-message" style={{ display: 'block' }}>
      <form>
        <div>
          <h4 className="subtitle">{t('common.financeApplication')}</h4>
        </div>
        <h1>{t('identity.cannotConfirm')}</h1>
        <div className="paragraph">
          <p>
            <Trans i18nKey="identity.hereToHelp" />
          </p>
          <p>{t('identity.notifyEmail')}</p>
        </div>
        <UploadFilesTable creditApp={creditApp} />
        <div className="btn-group">
          <ManageCoappButton creditAppId={creditApp.id} useChangeLabel />
          <RemoveCoappButton creditAppId={creditApp.id} />
          <CancelAppButton creditAppId={creditApp.id} />
        </div>
      </form>
    </main>
  ) : (
    <UnderReviewTile />
  )
}

export default FailedToObtainCoappCreditReportForm
