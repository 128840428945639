import translate from '@src/services/translate'
import { translateErrorCode } from '@src/types'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string
  reason?: string
  error?: FieldError
}

const SinInput = React.forwardRef<HTMLInputElement, Props>(({ id, reason, error, ...otherProps }, ref) => {
  const { t } = useTranslation()
  return (
    <div className={`control-group  ${error && 'error'}`}>
      {reason && <p>{translate(reason)}</p>}
      <label htmlFor={id}>{t('personalInformation.sin')}</label>
      <input
        ref={ref}
        id={id}
        placeholder="XXX XXX XXX"
        className="sin-input"
        type="text"
        {...otherProps}
        maxLength={11}
      />
      {error && <p className="error-message">{translateErrorCode(error)}</p>}
    </div>
  )
})

export default SinInput
