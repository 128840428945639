import { TermAmountPair } from '@src/api/compute-api'
import { EditWorksheetDto, FilteredCreditApplication, useUpdateWorksheet } from '@src/api/credit-api'
import AsyncActionButton from '@src/components/AsyncActionButton'
import BackButton from '@src/components/BackButton'
import RadioButtonList from '@src/components/RadioButtonList'
import { useTermAmountOptions } from '@src/data/credit-application-hooks'
import { reportErrorToConsole } from '@src/services/error-logger'
import { EPaymentFrequency } from '@src/types'
import { ChangeEventHandler, FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface SelectTermFormProps {
  creditApp: FilteredCreditApplication
  monthlyPaymentPerMonths: TermAmountPair[]
}

const SelectTermForm = ({ creditApp, monthlyPaymentPerMonths }: SelectTermFormProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [selectedLoanTerm, setSelectedLoanTerm] = useState(creditApp.worksheet?.term)
  const [submitWorksheetUpdate, isSubmitting] = useUpdateWorksheet()

  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths, i18n.language)

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault()
    if (selectedLoanTerm) {
      const dto: EditWorksheetDto = {
        ...creditApp.worksheet,
        term: selectedLoanTerm,
        paymentFrequency: EPaymentFrequency.Monthly,
        creditApplicationId: creditApp.id,
        create: creditApp.worksheet === null,
      }

      submitWorksheetUpdate(dto)
        .then(() => {
          navigate(`/creditapplication/${creditApp.id}`)
        })
        .catch(reportErrorToConsole)
    }
  }

  const handleTermSelected: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelectedLoanTerm(parseInt(e.currentTarget.value, 10))
  }

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form
        onSubmit={handleSubmit}
        style={{
          textAlign: 'center',
        }}
      >
        <div className="paragraph">
          <h3>{t('loan.selectLoanPeriod')}</h3>
          <div className="radiobuttons-stacked-container">
            <RadioButtonList
              name="term"
              onChange={handleTermSelected}
              options={termOptions}
              defaultValue={selectedLoanTerm?.toString()}
            />
          </div>
        </div>
        <div className="btn-group mobile-reversed">
          <BackButton />
          <AsyncActionButton isPending={isSubmitting} type="submit">
            {t('common.submit')}
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>
        </div>
      </form>
    </main>
  )
}

export default SelectTermForm
