import React from 'react'

type Props = { title: string; description: string }

const Loader = ({ title, description }: Props) => {
  return (
    <div className="loader-wrap">
      <div className="loader-box">
        <span className="loader" />
        <h3>{title}</h3>
        <div className="paragraph">
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default Loader
