import { useCancelReasons } from '@src/api/config-api'
import { useCancelApplication, useGetCreditApplicationById } from '@src/api/credit-api'
import { CustomerFeedbackDto, useSubmitFeedback } from '@src/api/feedback-api'
import { AsyncActionButton, Loader } from '@src/components'
import BackButton from '@src/components/BackButton'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'
import { EDecision } from '@src/types'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import FeedbackOption from './FeedbackOption'

const CancelApplicationPage = () => {
  const { t, i18n } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const navigate = useNavigate()

  const [withdrawalReasons, isLoadingReasons] = useCancelReasons(i18n.language)
  const [selectedReason, setSelectedReason] = useState('')
  const [shouldAskToContactThem, setShouldAskToContactThem] = useState(false)
  const [contactConfirmation, setContactConfirmation] = useState(false)
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, false)
  const [cancelAppAsync, isCancelling] = useCancelApplication()
  const [submitFeedback, isSubmittingFeedback] = useSubmitFeedback()

  const buildSelectedWithdrawalReasonFeedbackDto = useCallback(
    (contactClient: boolean): CustomerFeedbackDto => ({
      withdrawalReasonId: selectedReason,
      contactClient,
      isSatisfied: false,
      badReviewReasonId: '',
      creditApplicationId: id,
    }),
    [id, selectedReason],
  )

  const submitCancel = useCallback(
    (contactClient: boolean) => {
      if (creditApp) {
        const dto = buildSelectedWithdrawalReasonFeedbackDto(contactClient)
        const eventName =
          creditApp.normsDecision === null
            ? ETrackedEvents.WithdrewAfterQualif
            : creditApp.finalDecision.decision === EDecision.ManualRevision
              ? ETrackedEvents.WithdrewManualRevision
              : ETrackedEvents.WithdrewAfterQualif

        trackEvent(eventName, creditApp)
        submitFeedback(dto)
          .then(() => cancelAppAsync(creditApp.id))
          .then(() => navigate(`/creditapplication/${creditApp.id}`))
          .catch(reportErrorToConsole)
      }
    },
    [buildSelectedWithdrawalReasonFeedbackDto, cancelAppAsync, creditApp, navigate, submitFeedback],
  )

  const handleSubmit = (event: { preventDefault: () => void }) => {
    if (withdrawalReasons.find((x) => x.id === selectedReason && x.contactClient === true)) {
      setShouldAskToContactThem(true)
    } else {
      submitCancel(false)
    }

    event.preventDefault()
  }

  const handleContactClientAnswered = useCallback(
    (contactClient: boolean) => {
      const dto = buildSelectedWithdrawalReasonFeedbackDto(contactClient)
      if (!contactClient) {
        submitCancel(false)
      } else {
        setContactConfirmation(true)
        submitFeedback(dto).catch(reportErrorToConsole)
      }
    },
    [buildSelectedWithdrawalReasonFeedbackDto, submitCancel, submitFeedback],
  )

  if (isLoadingApp || isLoadingReasons)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main className="form-section">
      <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
        {!shouldAskToContactThem && !contactConfirmation && (
          <>
            <div className="control-group radiobuttons-stacked-container">
              <h1>{t('customerFeedback.formTitle')}</h1>
              <div>
                <p className="paragraph">{t('customerFeedback.formSubtitle')}</p>
              </div>
              {withdrawalReasons?.map((r) => (
                <div className="radiobuttons-stacked-cards">
                  <FeedbackOption
                    key={r.id}
                    id={r.id}
                    text={r.text}
                    selected={selectedReason}
                    onChange={setSelectedReason}
                  />
                </div>
              ))}
            </div>
            <div className="btn-group mobile-reversed">
              <BackButton />
              <AsyncActionButton type="submit" className="btn-blue" isPending={isSubmittingFeedback || isCancelling}>
                {t('common.submit')}
                <i className="fa-regular fa-arrow-right" />
              </AsyncActionButton>
            </div>
          </>
        )}
      </form>
      {shouldAskToContactThem && !contactConfirmation && (
        <div style={{ textAlign: 'center', marginTop: '30%' }}>
          <h1>{t('customerFeedback.confirmation')}</h1>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              type="button"
              className="btn btn-blue btn-xsmall"
              disabled={false}
              onClick={() => handleContactClientAnswered(true)}
            >
              {t('common.yes')}
            </button>
            <AsyncActionButton
              type="button"
              primary
              className="btn-xsmall"
              onClick={() => handleContactClientAnswered(false)}
              isPending={isSubmittingFeedback || isCancelling}
            >
              {t('common.no')}
            </AsyncActionButton>
          </div>
        </div>
      )}
      {contactConfirmation && (
        <div style={{ textAlign: 'center', marginTop: '30%' }}>
          <h1>{t('customerFeedback.customerConfirmation')}</h1>
        </div>
      )}
    </main>
  )
}

export default CancelApplicationPage
