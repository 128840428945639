import { ELoanPurpose, EMPTY_ARRAY, EPromoCodeStatus } from '@src/types'
import { MutationFunction, QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'merchants'
const SEARCH = 'search'
const DETAIL = 'detail'
const PROMO = 'promo'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  search: (filters: SearchMerchantDto) => [{ scope: SCOPE, entity: SEARCH }, filters] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  promo: (dto: PromotionCodeDto) => [{ scope: PROMO }, dto] as const,
}

export type Merchant = {
  id: string
  name: string
  address: string
  supportsPromotions: boolean
  serviceCategory: ELoanPurpose
  canSkipFlinks: boolean
}

export type SearchMerchantDto = {
  nameContains: string
  financingProgramId: string
  serviceCategory: string
  limit?: number
}

const searchMerchantsByName = async ({
  queryKey: [_, filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['search']>>) => {
  const response = await apiClient.post('api/merchant/search', filters)
  return response.data as Merchant[]
}

export function useSearchMerchant(filters: SearchMerchantDto): [Merchant[], boolean] {
  const enabled = !!filters.nameContains && filters.nameContains.length > 3
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.search(filters)],
    queryFn: searchMerchantsByName,
    enabled,
  })

  const ret = enabled && data ? data : EMPTY_ARRAY
  return [ret, isFetching]
}

const getMerchant = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const parts = id.split('*')
  const merchantId = parts.length === 1 ? id : parts[1]
  const response = await apiClient.get<Merchant>(`api/merchant/${encodeURIComponent(merchantId)}`)
  return response.data
}

export function useMerchantById(merchantId: string): [Merchant | null, boolean, Error | null] {
  const { isFetching, data, error } = useQuery({
    queryKey: keysFactory.detail(merchantId),
    queryFn: getMerchant,
    enabled: !!merchantId,
  })

  return [data ?? null, isFetching, error]
}

export type PromotionCodeDto = {
  promotionCode: string
  merchantId: string
}

export type PromotionValidationResult = {
  status: EPromoCodeStatus
  merchantPaymentPlanId: string
}

const validatePromoCode: MutationFunction<PromotionValidationResult, PromotionCodeDto> = async (
  dto: PromotionCodeDto,
) => {
  const response = await apiClient.post(`api/merchant/validatePromotionCode`, dto)
  return response.data as PromotionValidationResult
}

export function useValidatePromoCode(): [
  MutationFunction<PromotionValidationResult, PromotionCodeDto>,
  boolean,
  () => void,
] {
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: validatePromoCode,
  })

  return [mutateAsync, isPending, reset]
}
