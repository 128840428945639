import { FilteredCreditApplication } from '@src/api/credit-api'
import { CustomerFeedbackDto, useGetFeedback, useSubmitFeedback } from '@src/api/feedback-api'
import { Loader } from '@src/components'
import { isMedicardLoanPurpose } from '@src/data/merchant-selectors'
import { reportErrorToConsole } from '@src/services/error-logger'
import { EReviewLink } from '@src/types'
import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  creditApp: FilteredCreditApplication
}
const CustomerFeedbackTile = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [feedback, isLoadingFeedback] = useGetFeedback(creditApp.id)
  const [submitFeedback] = useSubmitFeedback()

  const reviewLink = isMedicardLoanPurpose(creditApp.loanPurposeId) ? EReviewLink.Medicard : EReviewLink.IFinance

  const postFeedback = useCallback(() => {
    const dto: CustomerFeedbackDto = {
      withdrawalReasonId: null,
      badReviewReasonId: null,
      contactClient: false,
      isSatisfied: true,
      creditApplicationId: creditApp.id,
    }

    submitFeedback(dto).catch(reportErrorToConsole)
  }, [creditApp.id, submitFeedback])

  const navigateToBadReview = useCallback(() => {
    navigate(`/creditApplication/${creditApp.id}/badReview`, {
      replace: false,
    })
  }, [creditApp.id, navigate])

  if (isLoadingFeedback) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return !feedback ? (
    <main className="general-message">
      <h4 className="subtitle">{t('overview.rateExperienceStep')}</h4>
      <h3>{t('feedback.rateYourExperience')}</h3>
      <div style={{ width: '100%' }}>
        <div className="button-group-2 feedback-btn-group">
          <div
            tabIndex={0}
            role="button"
            className="feedback-btn"
            onClick={() => navigateToBadReview()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigateToBadReview()
              }
            }}
          >
            <i className="fa-light fa-thumbs-down fa-flip-horizontal" />
            <button type="button" className="btn btn-blue">
              {t('feedback.badNews')}
            </button>
          </div>
          <div
            tabIndex={0}
            role="button"
            className="feedback-btn"
            onClick={postFeedback}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                postFeedback()
              }
            }}
          >
            <a href={reviewLink} target="_blank" rel="noreferrer">
              <i className="fa-light fa-thumbs-up" />
              <button type="button" className="btn btn-blue">
                {t('feedback.goodNews')}
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <main className="general-message">
      <h3>{t('feedback.thankYou')}</h3>
      <br />
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          onClick={() => navigate(`/creditapplication/${creditApp.id}`)}
          className="btn btn-blue primary-action-btn"
        >
          <i className="fa-light fa-arrow-left" />
          {t('common.backToApplication')}
        </button>
      </div>
    </main>
  )
}

export default React.memo(CustomerFeedbackTile)
