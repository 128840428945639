import { ApplicationRefinancingParams } from '@src/api/refinance-api'
import { formatDate } from '@src/services/Formatter'
import { Constants } from '@src/types'

const isRefinancingPossible = (params: ApplicationRefinancingParams | null): boolean => {
  if (!params) return false

  return !(params.availableAmount < Constants.MinLoanAmount || formatDate(params.expiresOn) < formatDate(new Date()))
}

export default isRefinancingPossible
