import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

const BackButton = ({ ...otherProps }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button type="button" {...otherProps} className="btn secondary-action-btn btn-simple" onClick={() => navigate(-1)}>
      <i className="fa-light fa-arrow-left" />
      {t('common.back')}
    </button>
  )
}

export default BackButton
