import { useTranslation } from 'react-i18next'

type Props = {
  title: string
}

const ErrorPage = ({ title }: Props) => {
  const { t } = useTranslation()
  return (
    <main className="step-0">
      <h4 className="subtitle">{title}</h4>
      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">{t('common.unexpectedError')}</div>
    </main>
  )
}

export default ErrorPage
